<template>
  <div>
    <Frame title="Gestión de correos bloqueados" :loading="loading" class="custom-frame">
      <div class="sticky-tool">
        <Layout>
          <Column size="4">
            <div>
              <Button icon="filter-variant" color="secondary" @click="onClickFilter">
                Filtros
              </Button>
            </div>
          </Column>
          <Column size="8" align="right">
            <div>
              <Button
                v-if="!showError && !showEmpty"
                icon="file-code-outline"
                color="secondary-outlined"
                @click="downloadReport"
              >
                Generar reporte
              </Button>
            </div>
          </Column>
        </Layout>
      </div>

      <div>
        <transition name="fade">
          <EmailBlacklistFilter v-show="!hideFilter" ref="filter" @executeQuery="executeQuery" />
        </transition>

        <Layout v-if="showError">
          <Column size="4" />
          <Column size="4">
            <ActionCard
              image="/assets/filter-start.svg"
              text="Ocurrió un problema, intentalo nuevamente."
            />
          </Column>
        </Layout>
        <Layout v-if="showEmpty && !showError">
          <Column size="4" />
          <Column size="4">
            <ActionCard
              image="/assets/blank.svg"
              text="Aún no ha sido registrado ningún email bloqueado."
            />
          </Column>
        </Layout>
      </div>

      <Grid
        v-if="datasource.data.length !== 0"
        :showAdd="false"
        :scrollable="true"
        :height="500"
        :data="datasource.data"
        :actionConditional="actions"
        class="emailblacklist-grid"
      >
        <template slot-scope="{ row }" tag="tr">
          <GridColumn title="Colaborador">
            {{ row.employee }}
          </GridColumn>
          <GridColumn title="Fecha de inicio">
            {{ row.createdDate }}
          </GridColumn>
          <GridColumn title="Tipo de correo">
            {{ row.emailType }}
          </GridColumn>
          <GridColumn title="Correo">
            {{ row.emailAddress }}
          </GridColumn>
          <GridColumn title="Estatus">
            <span v-if="row.inBlackList" class="pw-tag is-blocked"> Bloqueado </span>
          </GridColumn>
        </template>
      </Grid>
    </Frame>

    <Modal :active.sync="showModal" :width="450" class="modal-remove-email">
      <EmailBlacklistRemoveEmail :selectedItem="selectedItem" @close="closeModal()" />
    </Modal>
  </div>
</template>

<script>
import EmailBlacklistFilter from './EmailBlacklistFilter.vue';
import EmailBlacklistRemoveEmail from './EmailBlacklistRemoveEmail.vue';

export default {
  name: 'EmailBlacklist',
  components: {
    EmailBlacklistFilter,
    EmailBlacklistRemoveEmail,
  },
  data() {
    return {
      loading: false,
      hideFilter: true,
      showEmpty: true,
      showError: false,
      showModal: false,
      selectedItem: null,
      filters: {},

      datasource: {
        data: [],
        columns: [
          {
            field: 'fullName',
            title: 'Nombre',
          },
          {
            field: 'email',
            title: 'Correo electrónico',
          },
          {
            field: 'company',
            title: 'Compañia',
          },
          {
            field: 'role',
            title: 'Rol',
          },
        ],
        showAdd: true,
      },
    };
  },
  mounted() {
    this.getEmailsInBlacklist();
  },
  methods: {
    actions(item) {
      return item.inBlackList
        ? [{ text: 'Remover de lista negra', callback: this.removeEmail }]
        : [];
    },
    onClickFilter() {
      if (document.body.scrollTop > 0 || document.documentElement.scrollTop > 0) {
        this.hideFilter = false;
      } else {
        this.hideFilter = !this.hideFilter;
      }
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    executeQuery(filters) {
      this.hideFilter = true;
      this.filters = filters;
      this.getEmailsInBlacklist();
    },
    removeEmail(item) {
      this.showModal = true;
      this.selectedItem = item;
    },
    getEmailsInBlacklist() {
      this.loading = true;
      const endpoint = `stamp/org/EmailInBlackList`;
      this.$post(endpoint, this.filters, 'stamp')
        .then(({ data }) => {
          this.datasource.data = data.map((ele) => ({
            ...ele,
            createdDate: ele.createdDate
              ? this.$moment.utc(ele.createdDate).local().format('DD-MM-YYYY')
              : null,
          }));
          this.showBlank = false;
          this.showEmpty = data.length === 0;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.showError = true;
        });
    },
    closeModal() {
      this.selectedItem = null;
      this.getEmailsInBlacklist();
    },
    downloadReport() {
      const csv = this.$papa.unparse(this.datasource.data);
      this.$papa.download(csv, 'Emails bloqueados');
    },
  },
};
</script>

<style lang="scss">
.custom-frame {
  .pw-frame__header {
    margin-bottom: 0;
  }
}
.sticky-tool {
  position: sticky;
  top: 55px;
  background-color: #fff;
  z-index: 100;
  padding-top: 8px;
  padding-bottom: 8px;
}

.emailblacklist-grid {
  position: relative;
  th {
    position: sticky;
    top: 105px;
    z-index: 99;
  }
  .table-actions {
    margin: 0 !important;
  }
  .pw-tag.is-blocked {
    background-color: rgba(242, 85, 85, 0.2);
    border-color: rgba(242, 85, 85, 0.2);
    color: #f25555;
  }
}

.pw-button {
  .pw-button-icon {
    i.mdi {
      &.mdi-sync {
        display: inline-block;
        color: #5186ff;
        font-size: 120%;
        transform: rotate(90deg) !important;
      }
    }
  }
}
</style>
