<template>
  <div>
    <Frame>
      <Layout>
        <Column :size="1" class="alert-icon"></Column>
        <Column :size="11">
          <Layout>
            <Column class="modal-header">
              <h3>¿Deseas remover el correo seleccionado de la lista de correos bloqueados?</h3>
            </Column>
          </Layout>
          <Layout>
            <div class="modal-body">
              <Column>
                Esto removerá el correo de la lista de bloqueos y se le enviarán correos nuevamente,
                sin embargo si los estos continúan rebotando podría ocasionar el
                <strong>bloqueo de tu cuenta de Peopleware.</strong>
              </Column>
              <Column class="confirm-form">
                <Field :label="`Escribe &quot;${key}&quot; para confirmar`">
                  <Input v-model="confirmInput" @keypress.enter="executeQuery" />
                </Field>
              </Column>
            </div>
          </Layout>
          <Layout>
            <Column align="right">
              <Button color="terciary-outlined" @click="close()">Cancelar</Button>
              <Button color="quinary" :disabled="!isValidKey" @click="executeQuery">
                Remover
              </Button>
            </Column>
          </Layout>
        </Column>
      </Layout>
    </Frame>
  </div>
</template>

<script>
export default {
  name: 'EmailBlacklistRemoveEmail',
  props: {
    selectedItem: Object,
  },
  data() {
    return {
      key: 'REMOVER',
      confirmInput: '',
    };
  },
  methods: {
    close() {
      this.$parent.close();
      this.$emit('close');
    },
    executeQuery() {
      this.delete('stamp/org/EmailInBlackList', this.selectedItem.emailBlackListID, 'stamp').then(
        () => this.close()
      );
    },
  },
  computed: {
    isValidKey() {
      return this.confirmInput === this.key;
    },
  },
};
</script>
<style lang="scss">
.alert-icon {
  padding-top: 20px;
  &::before {
    content: '\F028';
    font-family: 'Material Design Icons';
    position: relative;
    color: #f25555;
    font-size: 20px;
  }
}

.modal-body {
  .confirm-form {
    margin-top: 25px;
  }
}
</style>
