<template>
  <div class="email-blacklist-filter">
    <Layout>
      <Column :size="columnSize">
        <Field label="Fecha de inicio">
          <Datepicker v-model="model.startDate" @input="onChangeValue" />
        </Field>
      </Column>
      <Column :size="columnSize">
        <Field label="Fecha término">
          <Datepicker v-model="model.endDate" :max="maxEndDate" :min="model.startDate" />
        </Field>
      </Column>
      <Column :size="columnSize">
        <Field label="Colaborador">
          <Input v-model="model.employeeName" @keypress.enter="executeQuery" />
        </Field>
      </Column>

      <Column :size="columnSize" align="right">
        <div class="buttons-filter">
          <Button icon="filter-remove-outline" color="secondary-outlined" @click="resetFilters" />
          <Button color="secondary" :disabled="!isValidFilter" @click="executeQuery">
            Consultar registros
          </Button>
        </div>
      </Column>
    </Layout>
  </div>
</template>

<script>
export default {
  name: 'EmailBlacklistFilter',
  data() {
    return {
      innerWidth: window.innerWidth,
      model: {
        employeeName: null,
        startDate: null,
        endDate: null,
      },
    };
  },
  methods: {
    resetFilters() {
      this.model = {
        employeeName: null,
        startDate: null,
        endDate: null,
      };
    },
    executeQuery() {
      this.$emit('executeQuery', {
        ...this.model,
        startDate: this.model.startDate ? this.$moment.utc(this.model.startDate) : null,
        endDate: this.model.startDate
          ? this.model.endDate
            ? this.$moment.utc(this.model.endDate).add(1, 'd')
            : this.$moment.utc().add(1, 'd')
          : null,
      });
    },
    onChangeValue(value) {
      if (this.$moment(this.model.endDate).isAfter(this.maxEndDate)) {
        this.model.endDate = this.maxEndDate;
      } else if (this.$moment(this.model.endDate).isBefore(value)) {
        this.model.endDate = value;
      }
    },
  },
  computed: {
    maxEndDate() {
      const currentDate = new Date(this.model.startDate);
      if (this.model.startDate) {
        return new Date(currentDate.setMonth(this.model.startDate.getMonth() + 6));
      }
      return null;
    },
    columnSize() {
      return this.innerWidth > 667 ? 3 : 12;
    },
    isValidFilter() {
      return !this.model.endDate || (!!this.model.endDate && !!this.model.startDate);
    },
  },
};
</script>

<style lang="scss">
.email-blacklist-filter {
  padding: 24px 24px 8px 24px;
  box-sizing: border-box;
  border: 1px solid #5186ff;
  margin-bottom: 24px;
}
.buttons-filter {
  padding-top: 24px;
  margin-bottom: 21px;
}
</style>
